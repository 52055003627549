import React from 'react'
import RolePages from '../Components/roleComponent';

function rolesPages(onLogout) {
  return (
    <div>
      <RolePages />
    </div>
  )
}

export default rolesPages