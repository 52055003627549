import React from 'react'
import TeleCallerDepartmentPages from '../Components/telecallerComponent';

function listtelecallerdeparmentPages(onLogout) {
  return (
    <div>
      <TeleCallerDepartmentPages />
    </div>
  )
}

export default listtelecallerdeparmentPages