import React from 'react'
import FrontDeskPages from '../Components/frontdeskComponent';

function frontdeskPages(onLogout) {
  return (
    <div>
      <FrontDeskPages />
    </div>
  )
}

export default frontdeskPages