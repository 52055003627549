import React from 'react'
import AddUserPages from '../Components/addUsersComponent';

function addUserPages(onLogout) {
  return (
    <div>
      <AddUserPages />
    </div>
  )
}

export default addUserPages