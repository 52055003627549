import React from 'react'
import SaleTeamPages from '../Components/addsaleteamComponent';

function addsaleteamPages(onLogout) {
  return (
    <div>
      <SaleTeamPages />
    </div>
  )
}

export default addsaleteamPages