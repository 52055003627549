import React from 'react'
import CounselingPages from '../Components/conselordepartmentComponent';

function counselingPages(onLogout) {
  return (
    <div>
      <CounselingPages />
    </div>
  )
}

export default counselingPages