import React from 'react';

const Footer = () => {
  return (
    <div>
     
    <section className='py-4'>
 <div className='container'>
   <div className='row'>
    <div className='col-12 col-xl-6 col-lg-6 col-md-6'>
      <div className='oname'>
      <span> Copyright © 2009-2024 The Good Student CO. All rights reserved.</span>
      </div>
      
  </div>
  <div className='col-12 col-xl-6 col-lg-6 col-md-6'>
        <div className='copyright'>
         <span>Design & Developed By</span> <a href='https://technogaze.com' >Technogaze Solutions Pvt.</a>
  </div>
  </div>
  </div>
  </div>
  </section>
    </div>
  );
};

export default Footer;
