import React from 'react'
import ListUserPages from '../Components/listUsersComponent';

function UserPages(onLogout) {
  return (
    <div>
      <ListUserPages />
    </div>
  )
}

export default UserPages