import React from 'react'
import ViewsUserPages from '../Components/viewsUsersComponent';

function viewsUserPages(onLogout) {
  return (
    <div>
      <ViewsUserPages />
    </div>
  )
}

export default viewsUserPages