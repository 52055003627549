import React from 'react'
import TeleCallerTeamPages from '../Components/telecallerteamComponent';

function listtelecallerteamPages(onLogout) {
  return (
    <div>
      <TeleCallerTeamPages />
    </div>
  )
}

export default listtelecallerteamPages