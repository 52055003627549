import React from 'react'
import AccountUserPages from '../Components/accountUsersComponent';

function accountUserPages(onLogout) {
  return (
    <div>
      <AccountUserPages />
    </div>
  )
}

export default accountUserPages