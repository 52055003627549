import React from 'react'
import DashBoard from '../Components/dashboardsComponent';
function DashBoardPage(onLogout) {
  return (
    <div>
      <DashBoard />
    </div>
  )
}

export default DashBoardPage