import React from 'react'
import FrontDeskListPages from '../Components/frontdesklistComponent';

function frontdesklistPages(onLogout) {
  return (
    <div>
      <FrontDeskListPages />
    </div>
  )
}

export default frontdesklistPages