import React from 'react';
import { Link } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';

function Navbarmenu() {
  return (
    <Navbar expand="lg" className="bg-body-tertiary cus_menu">
      <Container>
        <Navbar.Brand as={Link} to="/">
          <img src="../assets/img/home-img/TGSC-Logo.svg" className='img-fluid' alt="Logo" />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link as={Link} to="/">Home</Nav.Link>
            <Nav.Link as={Link} to="/about">About Us</Nav.Link>
            <Nav.Link as={Link} to="/learning-style-assessment">Learning Style Assessment</Nav.Link>
            <Nav.Link as={Link} to="/essay-services">Essay Services</Nav.Link>
            <Nav.Link as={Link} to="/sign-up">Sign Up</Nav.Link>
            <Nav.Link as={Link} to="/sign-in">Sign In</Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default Navbarmenu;
